@import './vars';

iframe#webpack-dev-server-client-overlay { display:none !important;}

button#menuSidebar {
  border:none;
  background: none;
}
.sidebar-body {
  overflow-y: scroll;
}

.MuiSnackbar-root .MuiPaper-root {
  background: $color-blue !important;
}
.main {
  height: calc(100vh - 200px) !important
}

.MuiInputBase-root {
  width: 100%;
}