.row.g-0.login,.login .col-wrapper,.login .card {
    height: 100vh;
}
.login {
    .card-sign {
        width: 100%;
    }
    
    .card-header, .card-body, .card-footer {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
    }
    
    .card-header {
        margin-top: 20vh;
    }

}