.assistantWrapper {
    & .typing {
        position: absolute;
        bottom: 55px;
        width: 100%;
        background: #FFF;
    
        padding: 10px;
    }
    & .main.main-app {
        margin: 0 !important
    }
    & .chat-body-content.ps{
        height: 85vh;
        overflow: scroll;
        }
    & .chat-panel.msg-show {
        height: 90vh !important;
    }

    & .chat-body-footer {
        padding-top: 50px;
    }
    .chat-bubble {
        position: absolute;
        background-color:#E6F8F1;
        padding:16px 28px;
        -webkit-border-radius: 20px;
        -webkit-border-bottom-left-radius: 2px;
        -moz-border-radius: 20px;
        -moz-border-radius-bottomleft: 2px;
        border-radius: 20px;
        border-bottom-left-radius: 2px;
        display:inline-block;
        bottom: 20px;
        left: 50px;
      }
      .typing {
        align-items: center;
        display: flex;
        height: 17px;
        top: 0;
        left: 0;
        position: relative;
        background: none;
      }
      .typing .dot {
        animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
        background-color: #aeaeae; //rgba(20,105,69,.7);
        border-radius: 50%;
        height: 7px;
        margin-right: 4px;
        vertical-align: middle;
        width: 7px;
        display: inline-block;
      }
      .typing .dot:nth-child(1) {
        animation-delay: 200ms;
      }
      .typing .dot:nth-child(2) {
        animation-delay: 300ms;
      }
      .typing .dot:nth-child(3) {
        animation-delay: 400ms;
      }
      .typing .dot:last-child {
        margin-right: 0;
      }
      
      @keyframes mercuryTypingAnimation {
        0% {
          transform: translateY(0px);
          background-color:#aeaeae; // rgba(20,105,69,.7);
        }
        28% {
          transform: translateY(-7px);
          background-color:#aeaeae; //rgba(20,105,69,.4);
        }
        44% {
          transform: translateY(0px);
          background-color: #aeaeae ; //rgba(20,105,69,.2);
        }
      }

}