.back {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: url('../../assets/assets/img/back_pay.jpg');
    background-size: cover;
    opacity: 0.5;
}
.leaf {
    width: 1200px;
    height: 650px;
    background-color: white;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 10px;
    padding: 25px;
    opacity: 0.8;
    border:1px solid #aeaeae;
    box-shadow: 8px 11px 11px -6px rgba(0,0,0,0.44);
    -webkit-box-shadow: 8px 11px 11px -6px rgba(0,0,0,0.44);
    -moz-box-shadow: 8px 11px 11px -6px rgba(0,0,0,0.44);
    font-size: 16px;
    overflow: scroll;
}
.title {
    padding:10px;
    font-size: 26px;

}
.content {
    display: flex;
    flex-direction: row;
}
.left, .right {
    width: 50%;
    padding:10px
}
ul {
    margin:0;
    padding:0;
    & li {
        display: flex;
        margin:0;
        padding:0;
        list-style: none;
    }
}
.center {
    position: relative;
    width: 100%;
    text-align: center;
}
.separator {
    padding: 25px 0;
}
.small {
    font-size: 12px;
}